<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/wrongBook' }">错题本</el-breadcrumb-item>
        <el-breadcrumb-item>错题重做</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="que flex" v-loading="loading">

        <el-image style="width: 60px; height: 21px" :src="imgUrl" fit="cover"></el-image>
        <div class="flex1">
          <div class="title"></div>
          <div class="content">
            <span class="index">{{currIndex+1}}/{{list.length}}</span>
            <span class="con"> {{currItem.content}}</span>

            <template v-if="currItem.type==='1'">
              <el-radio-group v-model="currItem.memberAnswer" class="radio-g">
                <el-radio label="A">{{currItem.optionA}}</el-radio>
                <el-radio label="B">{{currItem.optionB}}</el-radio>
                <el-radio label="C">{{currItem.optionC}}</el-radio>
                <el-radio label="D">{{currItem.optionD}}</el-radio>
              </el-radio-group>
            </template>

            <template v-if="currItem.type==='2'">
              <el-checkbox-group v-model="currItem.memberAnswer" class="radio-g">
                <el-checkbox label="A">{{currItem.optionA}}</el-checkbox>
                <el-checkbox label="B">{{currItem.optionB}}</el-checkbox>
                <el-checkbox label="C">{{currItem.optionC}}</el-checkbox>
                <el-checkbox label="D">{{currItem.optionD}}</el-checkbox>
              </el-checkbox-group>
            </template>

            <template v-if="currItem.type==='3'">
              <el-radio-group v-model="currItem.memberAnswer" class="radio-g">
                <el-radio label="1">对</el-radio>
                <el-radio label="2">错</el-radio>
              </el-radio-group>
            </template>

            <template v-if="currItem.type==='4'">
              <div class="radio-g">
                <el-input style="width:300px" v-model="currItem.memberAnswer" placeholder="请输入问题答案"></el-input>
              </div>

            </template>

          </div>

          <div class="btn" @click="handleSumbit(currItem)">
            <ComBtn label="确定" />
          </div>

          <div class="tip">
            {{handleTitle(currItem.type)}}，请选择你认为正确的答案！
          </div>

          <div class="flex" style="margin:20px 0">
            <span @click="nextPage(false)">
              <ComBtn label="上一题" />
            </span>

            <span @click="nextPage(true)">
              <ComBtn label="下一题" style="margin: 0 20px" />
            </span>

            <span @click="goBack" v-if="this.currIndex===this.list.length-1">
              <ComBtn label="返回" />
            </span>

          </div>

          <div class="detail" v-show="currItem.isAnswered">
            <span class="res f16" style="color:#289326" v-if="currItem.isTrue==='1'">回答正确</span>
            <span class="res f16" style="color:#DB3B3D" v-else>回答错误</span>
            <!-- <el-checkbox v-model="isAuto">答对自动下一题</el-checkbox>
            <span class="res">答对：<span style="color:#289326">{{correctNum}}题</span></span>
            <span class="res">答错：<span style="color:#DB3B3D">{{wrongNum}}题</span></span>
            <span class="res">正确率：<span style="color:#191818">{{correctRate.toFixed(2)}}%</span></span> -->
          </div>

          <template v-if="currItem.isAnswered">
            <div class="right-r" v-if="currItem.type==='3'">
              正确答案 ： {{currItem.answer === '1'? '对' : '错'}}
            </div>
            <div class="right-r" v-else>
              正确答案 ： {{currItem.answer }}
            </div>
            <div class="des flex">
              <div>试题详解</div>
              <div class="flex1 des-s">
                {{currItem.analysis}}
              </div>
            </div>
          </template>

        </div>
        </el-row>
      </div>

    </div>
  </transition>
</template>

<script>
import que1 from '@/assets/img/practice/que1.png'
import que2 from '@/assets/img/practice/que2.png'
import que3 from '@/assets/img/practice/que3.png'
import que4 from '@/assets/img/practice/que4.png'
export default {
  data () {
    return {
      radio: '',
      isAuto: false,
      objectCode: '',
      list: [],
      currIndex: 0,
      currItem: {},
      que1: que1,
      que2: que2,
      que3: que3,
      que4: que4,
      correctNum: 0,
      wrongNum: 0,
      memberCode: '',
      loading: false,
      count: 10,
    }
  },
  methods: {
    _getQuestionList () {
      // 查看所有题目
      // this.list = JSON.parse(sessionStorage.getItem('wrongList'))
      // this.currItem = this.list[this.currIndex]
      // this.$api.exercise.getQuestionList(
      //   {
      //     practiceCode: this.practiceCode
      //   }
      // ).then(res => {
      //   if (res.data.code === 200) {
      //     this.list = res.data.data
      //     this.list.forEach(item => {
      //       if (item.type === '2') {
      //         this.$set(item, 'memberAnswer', []);
      //       }
      //     })
      //     this.currItem = this.list[this.currIndex]
      //   }
      // })

      //所有详情
      this.$api.wBook
        .getDetailList({
          pageSize: this.count,
          condition: {
            objectCode: this.objectCode,
            memberCode: this.memberCode,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.list = res.data.data.data;
            this.list.forEach(item => {
              if (item.type === '2') {
                this.$set(item, 'memberAnswer', []);
              }
            })
            this.currItem = this.list[this.currIndex]
          }
        });

    },

    handleTitle (type) {
      //处理图片地址
      switch (type) {
        case '1':
          return '单选题'
        case '2':
          return '多选题'
        case '3':
          return '判断题'
        case '4':
          return '填空题'
        default:
          break;
      }
    },

    goBack () {
      this.$router.go(-1)
    },

    handleSumbit (item) {
      //提交
      let currItem = item
      // if (item.isAnswered) {
      //   this.$message.error('该题已经提交,请勿重复提交')
      //   return
      // }
      this.$set(item, 'isAnswered', true)

      // 多选题处理
      let temp = ''
      if (item.type === '2') {
        temp = JSON.parse(JSON.stringify(item))
        temp.memberAnswer = temp.memberAnswer.join('-')
        currItem = temp
      }

      if (item.answer === currItem.memberAnswer) {
        item.isTrue = '1'
        this.correctNum++
        this._reDoWrong(item)
      } else {
        item.isTrue = '2'
        this.wrongNum++
      }
      this.isAuto && this.nextPage(true)
    },


    _reDoWrong (item) {
      //提交重做题
      this.$api.wBook.reDoWrong({
        id: item.id,
      }).then(res => {
        if (res.data.code === 200) {

        }
      })
    },

    nextPage (isNext) {
      //翻页
      if (isNext) {
        if (this.currIndex === this.list.length - 1) {
          return
        }
        this.currItem = this.list[++this.currIndex]
      } else {
        if (this.currIndex == 0) {
          this.$message.error('已经是第一题')
          return
        }
        this.currItem = this.list[--this.currIndex]
      }
    },

    sumbitExam () {
      //提交测试
      this.loading = true
      this.$api.exercise.saveMemberPractice({
        memberCode: this.memberCode,
        practiceCode: this.practiceCode,
        detailList: this.list,
        trueNum: this.correctNum,
        falseNum: this.wrongNum,
        rate: this.correctRate / 100
      }).then(res => {
        this.loading = false
        if (res.data.code === 200) {
          this.$message.success('提交成功')
          this.$router.go(-1)
        }
      })
    },

  },

  computed: {
    correctRate () {
      //  正确率
      let isAnseredNum = 0
      this.list.forEach(item => {
        if (item.isAnswered) {
          isAnseredNum++
        }
      })
      if (!isAnseredNum) {
        return 0
      }
      return (this.correctNum / isAnseredNum) * 100
    },

    imgUrl () {
      switch (this.currItem.type) {
        case '1':
          return this.que1
        case '2':
          return this.que2
        case '3':
          return this.que3
        case '4':
          return this.que4
        default:
          return this.que2
      }
    }
  },



  created () {
    this.objectCode = this.$route.query.objectCode
    this.count = this.$route.query.pageSize
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode
    this._getQuestionList()
  },





}
</script>

<style lang="less" scoped>
.que {
  margin: 43px 0;
  text-align: left;
  font-size: 20px;
  .index {
    margin: 0 15px 0 20px;
  }
  .radio-g {
    margin-top: 30px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    .el-radio {
      margin: 5px 0;
    }
    .el-radio__label {
      font-size: 18px;
    }
    /deep/ .el-checkbox__label {
      font-size: 18px;
    }
  }

  .el-checkbox {
    margin: 5px 0;
  }

  /deep/ .el-radio__label {
    font-size: 18px;
  }
  .btn {
    margin-top: 10px;
    text-align: center;
  }

  .gray {
    background: #7f7f7f;
  }

  .tip {
    margin-top: 50px;
    font-size: 14px;
    color: #7f7f7f;
  }
  .detail {
    color: #7f7f7f;
    font-size: 14px;
    .res {
      // margin-left: 40px;
    }
  }
  .right-r {
    margin: 30px 0;
    padding-left: 20px;
    font-size: 20px;
    height: 44px;
    line-height: 44px;
    background: #f1faff;
  }
  .des {
    font-size: 20px;
    .des-s {
      margin-left: 5px;
      color: #7f7f7f;
      font-size: 16px;
    }
  }
}
</style>